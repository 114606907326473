define("sagostund/data/subjects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    people: ['Lasse kranförare', 'Mormor Eva', 'Syster Karin', 'Dansande Janne', 'David med gitarren', 'Emma jägare', 'Fåraherden Magnus', 'Brevbärare Daniel', 'Kristina den fina', 'Cycklande Angelina', 'Improvisirande Sanne', 'Maria med rullskriskor', 'Sjungande Suzan', 'Tuffa Jonny', 'Svart katt', 'Elefant', 'Giraff', 'Igelkott', 'Björn', 'Älg', 'Gösta Grävling', 'Haren', 'Kalle Kanin', 'Listiga Räven', 'Gris', 'Gorilla', 'Flodhäst', 'Trollkarlen', 'Roliga Viktor', 'Oroliga Lina', 'Sotare', 'Förskolelärare', 'Läkare', 'Galen Professor', 'Förvirrad Professor', 'Pumpaodlare', 'Riddaren', 'Kung', 'Drottning', 'Prinsessa', 'Prinsen', 'Smygande Ninjan', 'Baron Fisfjärt', 'Fräcka Fredrik'],
    things: ['Förtrollade glasögon', 'Mystisk flaska', 'Raket', 'Gammal ek', 'Skattkarta', 'Cykel', 'Rostig buss', 'Övergiven brevlåda', 'Traktor', 'Flygplan', 'Liten robot', 'Antik bok', 'Segelbåt', 'Förvandlingsbrygd', 'Kuslig flöjt', 'Hemlig nyckelring', 'Luftballong', 'Ficklampa', 'Kikare', 'Skattkista', 'Gammalt metspö', 'Kokande kittel'],
    places: ['Slott', 'Campingplats', 'Skottland', 'Gruva', 'Semesterresa', 'Höga bergen', 'Långt bort', 'Gömda sjön', 'Stora vida havet', 'Stora staden', 'Öde ö', 'Stuga i skogen', 'Djuga skogen', 'Ösregn'],
    mission: ['Skattjakt', 'Köra bil', 'Cycla', 'Jakt', 'Smyga', 'Leka', 'Busa', 'Jobba', 'Måla']
  };
  _exports.default = _default;
});