define("sagostund/routes/story", ["exports", "sagostund/data/subjects", "sagostund/data/stories"], function (_exports, _subjects, _stories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StoryRoute extends Ember.Route {
    model({
      story_id
    }) {
      return _stories.default.map((story, index) => Object.assign({
        id: index
      }, story))[story_id];
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.subjects = Object.keys(_subjects.default).map(type => {
        return _subjects.default[type].map((subject, index) => {
          return {
            id: `${type}-${index}`,
            name: subject,
            type: type
          };
        });
      }).flat();
    }

  }

  _exports.default = StoryRoute;
});