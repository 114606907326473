define("sagostund/data/stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: 'Det var en gång…',
    lines: [{
      text: 'Det var en gång',
      types: ['people']
    }, {
      text: 'En dag så uppstod ett problem',
      types: ['things', 'people']
    }, {
      text: 'Den första lösningen (som misslyckas)',
      types: ['things']
    }, {
      text: 'Men med hjälp av',
      types: ['people']
    }, {
      text: 'Löser dom problemet med',
      types: ['things']
    }]
  }, {
    name: 'Bas',
    lines: [{
      text: 'Huvudperson',
      types: ['people']
    }, {
      text: 'Plats',
      types: ['places']
    }, {
      text: 'Mål/Uppdrag/Äventyr',
      types: ['people', 'things']
    }, {
      text: 'Hinder/Problem',
      types: ['people', 'things']
    }, {
      text: 'Lösning',
      types: ['things']
    }]
  }, {
    name: 'Evas',
    lines: [{
      text: 'Huvudperson',
      types: ['people']
    }, {
      text: 'I/på/från',
      types: ['places']
    }, {
      text: 'Hinder/Problem',
      types: ['mission']
    }, {
      text: 'I/på/från',
      types: ['places']
    }, {
      text: 'Biroll',
      types: ['people']
    }, {
      text: 'Den första lösningen (som misslyckas)',
      types: ['things']
    }, {
      text: 'Lösning',
      types: ['things']
    }]
  }];
  _exports.default = _default;
});