define("sagostund/components/story-teller", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-animated/transitions/fade"], function (_exports, _component, _emberConcurrencyDecorators, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex flex-col h-full justify-between">
    <ol>
      {{#animated-each @story.lines key="text" use=this.transition as |line index|
      }}
        <StoryLine
          @index={{index}}
          @tagName="li"
          @line={{line}}
          @pickSubject={{perform this.pickSubject}} />
      {{/animated-each}}
    </ol>
  </div>
  */
  {
    "id": "0+8GAyBD",
    "block": "[[[10,0],[14,0,\"flex flex-col h-full justify-between\"],[12],[1,\"\\n  \"],[10,\"ol\"],[12],[1,\"\\n\"],[6,[39,0],[[30,1,[\"lines\"]]],[[\"key\",\"use\"],[\"text\",[30,0,[\"transition\"]]]],[[\"default\"],[[[[1,\"      \"],[8,[39,1],null,[[\"@index\",\"@tagName\",\"@line\",\"@pickSubject\"],[[30,3],\"li\",[30,2],[28,[37,2],[[30,0,[\"pickSubject\"]]],null]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@story\",\"line\",\"index\"],false,[\"animated-each\",\"story-line\",\"perform\"]]",
    "moduleName": "sagostund/components/story-teller.hbs",
    "isStrictMode": false
  });

  let StoryTellerComponent = (_dec = Ember._tracked, _dec2 = Ember.computed.setDiff('args.subjects', 'usedSubjects'), (_class = class StoryTellerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usedSubjects", _descriptor, this);

      _defineProperty(this, "transition", _fade.default);

      _initializerDefineProperty(this, "availableSubjects", _descriptor2, this);
    }

    *pickSubject(storyLine) {
      let types = storyLine.args.line.types;
      let availableSubjects = this.availableSubjects.filter(subject => {
        return types.includes(subject.type);
      });
      let previousSubject = storyLine.subject;
      let newSubject = availableSubjects[Math.floor(Math.random() * availableSubjects.length)];
      this.usedSubjects.pushObject(newSubject);
      this.usedSubjects.removeObject(previousSubject);
      yield storyLine.subject = newSubject;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usedSubjects", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "availableSubjects", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pickSubject", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "pickSubject"), _class.prototype)), _class));
  _exports.default = StoryTellerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StoryTellerComponent);
});