define("sagostund/routes/index", ["exports", "sagostund/data/stories"], function (_exports, _stories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    model() {
      return _stories.default.map((story, index) => Object.assign({
        id: index
      }, story));
    }

  }

  _exports.default = IndexRoute;
});