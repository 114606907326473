define("sagostund/tailwind/config", [], function () {
  "use strict";

  module.exports = {
    purge: [],
    theme: {
      extend: {
        colors: {
          thunderbird: {
            100: '#FAECE9',
            200: '#F2CEC8',
            300: '#E9B1A7',
            400: '#D97766',
            500: '#C93C24',
            600: '#B53620',
            700: '#792416',
            800: '#5A1B10',
            900: '#3C120B'
          },
          'silver-tree': {
            100: '#F2F8F5',
            200: '#DEEDE6',
            300: '#CAE3D6',
            400: '#A2CDB8',
            500: '#7AB899',
            600: '#6EA68A',
            700: '#496E5C',
            800: '#375345',
            900: '#25372E'
          }
        }
      }
    },
    variants: {},
    plugins: [require("tailwindcss-font-inter")()]
  };
});