define("sagostund/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wquetC5g",
    "block": "[[[10,0],[14,0,\"flex flex-col h-screen\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"font-extrabold mb-1 text-4xl text-center text-thunderbird-500\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"Sagostund\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,3],null,null,null]],[],false,[\"link-to\",\"component\",\"-outlet\",\"animated-orphans\"]]",
    "moduleName": "sagostund/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});