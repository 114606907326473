define("sagostund/components/story-line", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mx-5 my-3 flex flex-col" {{on "click" (fn @pickSubject this)}}>
    <span class="text-thunderbird-400 text-2xl">
      {{@line.text}}
    </span>
    <span class="text-silver-tree-700 text-2xl">
      {{this.subject.name}}
    </span>
  </div>
  */
  {
    "id": "e+KoyrRM",
    "block": "[[[11,0],[24,0,\"mx-5 my-3 flex flex-col\"],[4,[38,0],[\"click\",[28,[37,1],[[30,1],[30,0]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"text-thunderbird-400 text-2xl\"],[12],[1,\"\\n    \"],[1,[30,2,[\"text\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"text-silver-tree-700 text-2xl\"],[12],[1,\"\\n    \"],[1,[30,0,[\"subject\",\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@pickSubject\",\"@line\"],false,[\"on\",\"fn\"]]",
    "moduleName": "sagostund/components/story-line.hbs",
    "isStrictMode": false
  });

  let StoryLineComponent = (_dec = Ember._tracked, (_class = class StoryLineComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "subject", _descriptor, this);

      this.args.pickSubject(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "subject", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StoryLineComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, StoryLineComponent);
});