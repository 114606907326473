define("sagostund/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qw5sF5CL",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"story\",[30,1]]],[[\"default\"],[[[[1,[30,1,[\"name\"]]]],[]]]]],[1,\"\\n\"]],[1]],null]],[\"story\"],false,[\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "sagostund/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});